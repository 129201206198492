<template>
    <v-container>
        <!-- heading -->
        <v-row>
            <v-col cols="12" justify="center">
                <h1 class="text-center">Now That's Campin'</h1>
            </v-col>
        </v-row>
        <!-- blurb -->
        <v-row justify="center">
            <v-col cols="6">
                <h3>Basic Camping Stuff for One Person</h3>
                <p class="text-body-2 font-weight-light font-italic"><strong>all of these items below are things we have used, or someone we know has used on a camping / backpacking trip with us.</strong> <span class="text-decoration-underline">if you happen to purchase these items on Amazon or Amazon Prime, then please consider using these affiliate links. we appreciate your help and hope you have a great day!</span></p>
            </v-col>
        </v-row>
        <!-- sleep -->
        <v-row justify="center">
            <v-col cols="6">
                <h5>Sleep</h5>
                <ul>
                    <li><a target="_blank" href="https://www.amazon.com/Stansport-713-84-B-Scout-Backpack-Tent/dp/B0006V2B2I/ref=sr_1_8?crid=XBXAJNHH2447&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=1224e687a9fbe8016b5cefdaa713ac7f&camp=1789&creative=9325">Pup Tent</a> (affiliate link 🙏🏼) — aka the classic A-Frame tent. it's a triangular prism of a tent. Probably called "pup tents" because soldiers were once issued these tents, and they called them "dog tent" for their resemblance to a kennel.</li>
                    <!-- <li>Comfortable: Dome Tent</li> -->
                    <!-- <li>Family-Size: 10-Person Tent</li> -->
                    <li><a target="_blank" href="https://www.amazon.com/dp/B00069PFKK?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=c536c5eb6d788c5592bc76521c70e627&camp=1789&creative=9325">Sleeping Pad</a> (affiliate link 🙏🏼)</li>
                    <li><a target="_blank" href="https://www.amazon.com/Coleman-Sleeping-Brazos-Cold-Weather-Camping/dp/B00363V3OK?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=9bea174ca1b52de4aaa51f9b272c4c3d&camp=1789&creative=9325">Sleeping Bag</a> (affiliate link 🙏🏼)</li>
                    <li><a target="_blank" href="https://www.amazon.com/BARST-Water-Resistant-Insulated-Compression-Survival/dp/B0B3DDKLP7?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=3de06b7f6a382624f20b0ec54bce9e7f&camp=1789&creative=9325">Woobie</a> (affiliate link 🙏🏼)</li>
                </ul>
            </v-col>
        </v-row>
        <!-- fire -->
        <v-row justify="center">
            <v-col cols="6">
                <h5>Fire</h5>
                <ul>
                    <li>Firewood</li>
                    <li><a target="_blank" href="https://www.amazon.com/Better-Wood-Products-Firestarter-1-5-Pounds/dp/B003AYWV50?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=21869d6694da84b4c24e2850c96db6ce&camp=1789&creative=9325">Fire Starter - Fatwood</a> (affiliate link 🙏🏼) — natural, resin-soaked pieces of wood from downed trees that are guaranteed to catch on fire and stay burning, helping to start you a big ol' campfire. you can feather the fatwood to help it catch more quickly-like.</li>
                    <li><a target="_blank" href="https://www.amazon.com/Duraflame-Natural-Firelog-Hours-lbs/dp/B000Q5NF2O?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=b6571daab78c4a7b25dbff82f59ffb03&camp=1789&creative=9325">Fire Starter - Duraflame</a> (affiliate link 🙏🏼) — but wouldn't you rather learn how to make a real fire from scratch?</li>
                    <li><a target="_blank" href="https://www.amazon.com/KURUI-Camping-Camp-Hatchet-Shock-Absorbent/dp/B08RDWQZSZ?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=90789d21539a9a768f8c3f4a0f3435c4&camp=1789&creative=9325">Hatchet</a> (affiliate link 🙏🏼)</li>
                    <li></li>
                </ul>
            </v-col>
        </v-row>
        <!-- clothes -->
        <v-row justify="center">
            <v-col cols="6">
                <h5>Clothes</h5>
                <ul>
                    <li><a target="_blank" href="https://www.amazon.com/Outdoor-Protection-Waterproof-Packable-Gardening/dp/B0BWHKYMMF?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=ff66270beaeffeb0a2486e802b09226a&camp=1789&creative=9325">Day Hat</a> (affiliate link 🙏🏼) — baseball cap or a boonie, just something with a brim</li>
                    <li><a target="_blank" href="https://www.amazon.com/Rothco-Genuine-U-S-N-Wool-Watch/dp/B000LK78L8?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=a3650c564929a81d2c3d28e16619df3f&camp=1789&creative=9325">Night Hat</a> (affiliate link 🙏🏼) — like a beanie/watch cap, something to keep your head warm at night bc it gets cold outside brr 🥶</li>
                    <li><a target="_blank" href="https://www.amazon.com/LINVO-Classic-Design-Polarized-Sunglasses/dp/B07MCXWSH6?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=72b069e1e8346e81b68bb0993eddc634&camp=1789&creative=9325">Shades / Sunglasses</a> (affiliate link 🙏🏼) — ohhh my goodness, you need to protect your eyeballs, people. you will feel so much more comfortable and actually <em>enjoy yourself</em> when you're not squinting all day from blinding brightness. go for polarized lenses if you can.</li>
                    <li><a target="_blank" href="https://www.amazon.com/Smartwool-Classic-Extra-Cushion-Socks/dp/B0943MN89T?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=e852574d96873a437962b58c54476f99&camp=1789&creative=9325">Wool Socks</a> (affiliate link 🙏🏼) (always have extra socks, always)</li>
                    <li><a target="_blank" href="https://www.amazon.com/Extra-Large-Paisley-Bandana-Inch-Orange/dp/B094K38PWR?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=3766e89215e6d104364a9443bf6dfb11&camp=1789&creative=9325">Gaiter or Bandana</a> (affiliate link 🙏🏼) (multi-purpose)</li>
                    <li><a target="_blank" href="https://www.amazon.com/FUNKYMONKEY-Slides-Outdoor-Comfort-Sandals/dp/B0946R24CM?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=c8d29f336018dac22e229afbc607c949&camp=1789&creative=9325">Camp Shoes / Flip Flops</a> (affiliate link 🙏🏼)</li>
                </ul>
            </v-col>
        </v-row>
        <!-- hygiene/first aid -->
        <v-row justify="center">
            <v-col cols="6">
                <h5>Hygiene / First Aid</h5>
                <ul>
                    <li><a target="_blank" href="https://www.amazon.com/PURELL-Advanced-Sanitizer-Refreshing-Bottle/dp/B004F7LDFK/ref=sr_1_7?crid=19AZ2GVY98Q55&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=06e5f55793fd1dde2e8ce08f5191a8dd&camp=1789&creative=9325">Hand Sanitizer</a> (affiliate link 🙏🏼)</li>
                    <li><a target="_blank" href="https://www.amazon.com/Huggies-Sensitive-Unscented-Hypoallergenic-Flip-Top/dp/B08QRT84WJ/ref=sr_1_6?crid=E1B35TV6PL2A&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=dcc46682baedbfa1d609b7b24071c25e&camp=1789&creative=9325">Baby Wipes</a> (affiliate link 🙏🏼)</li>
                    <li>Paper Towels / Cloth Napkins / Bandana</li>
                    <li><a target="_blank" href="https://www.amazon.com/Ziploc-Freezer-Bags-Gallon-Count/dp/B00CQAHOCO?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=21aa6ba642e1e1a8a3d913ff1662db4a&camp=1789&creative=9325">Ziploc Bags</a> (affiliate link 🙏🏼) (quart or gallon size, especially if there are no toilets at your campsite!)</li>
                    <li><a target="_blank" href="https://www.amazon.com/Glad-ForceFlex-Kitchen-Drawstring-Unscented/dp/B0014D5NWW?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=1e34dc90bcea9d6bac6dbfdb3c9b2d3d&camp=1789&creative=9325">Trash Bags</a> (affiliate link 🙏🏼) (13 gal trash liners from your kitchen, average 2-3 per day)</li>
                    <li><a target="_blank" href="https://www.amazon.com/Band-Aid-Flexible-Fabric-Adhesive-Bandages/dp/B0057RMOSM?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=ece913b3b01353dea5acf1d64c38b165&camp=1789&creative=9325">Band-Aids</a> (affiliate link 🙏🏼) (all econo-political debates aside, we like Band-Aid brand Flexible Fabric because they just stick and stay on, no diggity, no doubt)</li>
                    <li><a target="_blank" href="https://www.amazon.com/CareALL%25C2%25AE-Antibiotic-Scratches-Infection-Ingredients/dp/B0B17C2456?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=cae73fd72e79e1ff114a5f2680661090&camp=1789&creative=9325">Triple Antibiotic</a> (affiliate link 🙏🏼) (keep it simple, señor: sanitize your hands, clean the wound/remove debris with clean water, triple antibiotic to broad-stroke cover/heal/protect, then slap on a band-aid so stuff/dirt/germs can't get in)</li>
                    <li><a target="_blank" href="https://www.amazon.com/Amazon-Basics-Black-Clippers-2-Pack/dp/B09VPM99J8?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=a72eb52bde24dd82bc28f194d37d6f45&camp=1789&creative=9325">Nail Clippers</a> (affiliate link 🙏🏼) (an absolute must: completely useless until it becomes life-alteringly necessary #hangnail)</li>
                </ul>
            </v-col>
        </v-row>
        <!-- eat / cook -->
        <v-row justify="center">
            <v-col cols="6">
                <h5>Eat / Cook</h5>
                <ul>
                    <li><a target="_blank" href="https://www.amazon.com/Cuisinart-C77TR-8CF-Triple-Rivet-Collection/dp/B01C0XVBSY/ref=sr_1_43?crid=1EJA6K1VXUJWL&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=01323bc629c297fcc89cc9de9218cc4c&camp=1789&creative=9325">Chef's Knife</a> (affiliate link 🙏🏼) (or just bring the one you got at home)</li>
                    <li><a target="_blank" href="https://www.amazon.com/Reynolds-Wrap-Heavy-Strength-50/dp/B00279LYL6/ref=sr_1_7?crid=9382KAM7IEI7&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=9f0bb4d2409791df6f315c8b5450b121&camp=1789&creative=9325">Heavy Duty Alumnium Foil</a> (affiliate link 🙏🏼) - do not skimp out; go for the heavy duty foil. Basically, spread out some tin foil, chop up any protein and veggies, wrap/seal everything up into a pouch, then lay on some hot campfire coals for 30 mins.</li>
                    <li><a target="_blank" href="https://amzn.to/46goTwC">Oil Sprayer</a> (affiliate link 🙏🏼) - it can get <em>dusty</em> when you're out camping. keep your <em>dirt</em>-y hands & clothes clean by using this oil sprayer. no more oil spills, no more oily messes.</li>
                    <li>Cutting Board (or just use a plate, or just bring one from home)</li>
                    <li>Seasoning & Condiments (salt, pepper, garlic, ketchup, mustard) — honestly, just bring these from home. raid your spice rack and put em all in a big <a target="_blank" href="https://www.amazon.com/Ziploc-Freezer-Bags-Gallon-Count/dp/B00CQAHOCO?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=21aa6ba642e1e1a8a3d913ff1662db4a&camp=1789&creative=9325">ziploc bag</a> (affiliate link 🙏🏼). easy peasy lemon squeezy.</li>
                    <li>Bowl and/or Plate — just bring one from home. try to avoid glass or anything that might break. maybe stick cloth napkins or paper towels or sheets of newspaper in between the things, like you would when packing to move to a new place.</li>
                    <li>Spoon, fork, knife — just bring a set from home. Pro tip: long-handled and big-headed (like a long soup spoon) works great. long handled really helps in the outdoors.</li>
                    <li>Cup or Mug — just bring one from home, and it should be able to hold hot or cold liquids</li>
                    <li>Booze — use your discretion and follow all laws. have fun, be safe.</li>
                    <li>S'mores — noice. graham crackers, milk chocolate bars, big marshmallows. i like to toast 2 marshmallows so they get big and warm and soft, and a little crunchy on the outside. but NOT burnt. if it catches on fire, then i just blow it out immediately, peel off the burnt, and eat the remaining core right away... on to the next marshmallow. hbu?</li>
                </ul>
            </v-col>
        </v-row>
        <!-- sit/relax -->
        <v-row justify="center">
            <v-col cols="6">
                <h5>Sit / Relax</h5>
                <ul>
                    <li><a target="_blank" href="https://www.amazon.com/Oversized-Camping-Fortable-Portable-Adjustable/dp/B0BWV1KZMC?&_encoding=UTF8&tag=nowthatscampi-20&linkCode=ur2&linkId=3f6158b4ffe929062cc6d1f5643a458f&camp=1789&creative=9325">Camping Chair</a> (affiliate link 🙏🏼)</li>
                    <li>Shade</li>
                    <li>Hammock</li>
                    <li>Lounge Chair</li>
                </ul>
            </v-col>
        </v-row>
        <!-- activities -->
        <v-row justify="center">
            <v-col cols="6">
                <h5>Activities</h5>
                <ul>
                    <li>Hiking Boots</li>
                    <li>Camelbak</li>
                    <li>Walking Stick</li>
                    <li>Playing Cards</li>
                    <li>Backgammon</li>
                    <li>Swimming Trunks</li>
                    <li>Bikini</li>
                    <li>Clmbing Shoes</li>
                    <li>Bouldering Crash Pad</li>
                    <li>Fishing Kit</li>
                    <li></li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() { 
        return {

        };
    },
}
</script>
<style lang="css" scoped>
h1 { font-size: 50px; }
h3 { font-size: 26px; }
h5 { font-size: 18px; text-decoration: underline; }
li { margin-left: 18px; margin-bottom: 14px; }
a { text-decoration: underline; color: darkslategray; }
</style>